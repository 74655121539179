import { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import './FAQ.css';

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="faq-item">
            <button className="faq-question" onClick={() => setIsOpen(!isOpen)}>
                <span className="question-text">{question}</span>
                <span className="faq-icon">
                    <FontAwesomeIcon icon={isOpen ? faCircleMinus : faCirclePlus} />
                </span>
            </button>
            {isOpen && <div className="faq-answer" dangerouslySetInnerHTML={{ __html: answer }} />}
        </div>
    );
};


FAQItem.propTypes = {
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
};

export default FAQItem;
