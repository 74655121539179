import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import emailjs from '@emailjs/browser';
import {
    faTimes,
    faMapMarkerAlt,
    faLocationDot,
    faArrowLeft,
    faFile,
    faImage,
    faMap
} from '@fortawesome/free-solid-svg-icons';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import './QuoteModal.css';

const initialFormData = {
    name: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
};

const initialManualAddress = {
    street: '',
    sector: '',
    city: '',
    reference: ''
};

const MapLocationPicker = ({ onLocationSelect }) => {
    const [marker, setMarker] = useState(null);
    const [searchBox, setSearchBox] = useState(null);

    const mapStyles = {
        height: "300px",
        width: "100%",
        borderRadius: "4px",
        marginTop: "0.75rem"
    };

    const searchBoxStyle = {
        boxSizing: `border-box`,
        border: `1px solid transparent`,
        width: `240px`,
        height: `32px`,
        padding: `0 12px`,
        borderRadius: `3px`,
        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
        fontSize: `14px`,
        outline: `none`,
        textOverflow: `ellipses`,
        position: "absolute",
        left: "50%",
        marginLeft: "-120px",
        marginTop: "10px",
        background: "white"
    };

    const defaultCenter = {
        lat: 18.4861,
        lng: -69.9312
    };

    const handleMapClick = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setMarker({ lat, lng });
        onLocationSelect({ latitude: lat, longitude: lng });
    };

    const onLoad = (ref) => {
        setSearchBox(ref);
    };

    const onPlacesChanged = () => {
        if (searchBox) {
            const places = searchBox.getPlaces();
            if (places.length === 0) return;

            const place = places[0];
            if (!place.geometry) return;

            const newLocation = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };

            setMarker(newLocation);
            onLocationSelect({
                latitude: newLocation.lat,
                longitude: newLocation.lng
            });
        }
    };

    return (
        <LoadScript
            googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY"
            libraries={["places"]}
        >
            <div style={{ position: "relative" }}>
                <StandaloneSearchBox
                    onLoad={onLoad}
                    onPlacesChanged={onPlacesChanged}
                >
                    <input
                        type="text"
                        placeholder="Buscar ubicación..."
                        style={searchBoxStyle}
                    />
                </StandaloneSearchBox>
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={13}
                    center={marker || defaultCenter}
                    onClick={handleMapClick}
                >
                    {marker && (
                        <Marker
                            position={marker}
                        />
                    )}
                </GoogleMap>
            </div>
        </LoadScript>
    );
};
const QuoteModal = ({ show, onClose }) => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState(initialFormData);
    const [coordinates, setCoordinates] = useState(null);
    const [locationError, setLocationError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [locationType, setLocationType] = useState('current');
    const [manualAddress, setManualAddress] = useState(initialManualAddress);
    const [filePreview, setFilePreview] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (!show) {
            resetForm();
        }
    }, [show]);

    const resetForm = () => {
        setStep(1);
        setFormData(initialFormData);
        setCoordinates(null);
        setLocationError('');
        setIsLoading(false);
        setLocationType('current');
        setManualAddress(initialManualAddress);
        setFilePreview(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 48 * 1024) { // 48KB límite
                alert('El archivo es demasiado grande. Máximo 48KB.');
                e.target.value = '';
                setFilePreview(null);
                return;
            }
    
            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setFilePreview({
                        type: 'image',
                        url: e.target.result,
                        name: file.name
                    });
                };
                reader.readAsDataURL(file);
            } else {
                setFilePreview({
                    type: 'file',
                    name: file.name
                });
            }
        } else {
            setFilePreview(null);
        }
    };

    const handleGetLocation = () => {
        setIsLoading(true);
        setLocationError('');

        if (!navigator.geolocation) {
            setLocationError('La geolocalización no está soportada por su navegador');
            setIsLoading(false);
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                setCoordinates({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
                setIsLoading(false);
            },
            (error) => {
                setLocationError('No se pudo obtener su ubicación');
                setIsLoading(false);
                console.error('Error getting location:', error);
            }
        );
    };

    const handleLocationTypeChange = (type) => {
        setLocationType(type);
        if (type === 'manual') {
            setCoordinates(null);
        } else if (type === 'current') {
            setManualAddress(initialManualAddress);
            setCoordinates(null);
        } else {
            setManualAddress(initialManualAddress);
        }
    };

    const handleAddressChange = (field, value) => {
        setManualAddress(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleNextStep = (e) => {
        e.preventDefault();
        setStep(2);
    };

    const handleBack = () => {
        setStep(1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
    
        try {

            let fileInfo = 'No se adjuntó ningún archivo';
            if (fileInputRef.current?.files[0]) {
                const file = fileInputRef.current.files[0];
                fileInfo = `
                    Nombre: ${file.name}
                    Tipo: ${file.type}
                    Tamaño: ${(file.size / 1024).toFixed(2)} KB
                `;
            }
    
            const templateParams = {
                user_name: formData.name,
                user_lastname: formData.lastName,
                user_email: formData.email,
                user_phone: formData.phone,
                message: formData.message,
                location: locationType === 'current' 
                    ? `Coordenadas: ${coordinates.latitude}, ${coordinates.longitude}`
                    : `Calle: ${manualAddress.street}
                       Sector: ${manualAddress.sector}
                       Ciudad: ${manualAddress.city}
                       ${manualAddress.reference ? 'Referencia: ' + manualAddress.reference : ''}`,
                attachment_info: fileInfo,
                submit_date: new Date().toLocaleString()
            };
    
            await emailjs.send(
                'service_47tl42g',
                'template_u9xclz7',
                templateParams,
                'xtAVnyuYIWvuFxdyD'
            );
    
            // Éxito
            const notification = document.createElement('div');
            notification.className = 'success-notification';
            notification.innerHTML = `
                <div class="notification-content">
                    <p>✅ ¡Cotización enviada con éxito!</p>
                    <small>Nos pondremos en contacto pronto</small>
                </div>
            `;
            document.body.appendChild(notification);
            setTimeout(() => {
                notification.remove();
            }, 3000);
            resetForm();
            onClose();
        } catch (error) {
            console.error('Error:', error);
            const notification = document.createElement('div');
            notification.className = 'error-notification';
            notification.innerHTML = `
                <div class="notification-content">
                    <p>❌ Error al enviar la cotización</p>
                    <small>Por favor, intente nuevamente</small>
                </div>
            `;
            document.body.appendChild(notification);
            setTimeout(() => {
                notification.remove();
            }, 3000);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target.className === 'quote-modal-overlay') {
            onClose();
            resetForm();
        }
    };

    if (!show) return null;return (
        <div className="quote-modal-overlay" onClick={handleOverlayClick}>
            <div className="quote-modal-content">
                <button className="modal-close-button" onClick={() => { onClose(); resetForm(); }}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                <div className="quote-modal-body">
                    {step === 1 ? (
                        <>
                            <h2 className="quote-modal-title">Solicitar Cotización</h2>
                            <p className="quote-modal-subtitle">
                                Complete el formulario y nos pondremos en contacto con usted lo antes posible.
                            </p>

                            <form onSubmit={handleNextStep} className="quote-modal-form">
                                <div className="quote-form-row">
                                    <div className="quote-form-group">
                                        <label htmlFor="name">Nombre</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="Escriba aquí"
                                            required
                                        />
                                    </div>
                                    <div className="quote-form-group">
                                        <label htmlFor="lastName">Apellido</label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            placeholder="Escriba aquí"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="quote-form-row">
                                    <div className="quote-form-group">
                                        <label htmlFor="email">Correo Electrónico</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Escriba aquí"
                                            required
                                        />
                                    </div>
                                    <div className="quote-form-group">
                                        <label htmlFor="phone">Teléfono (opcional)</label>
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            placeholder="Escriba aquí"
                                        />
                                    </div>
                                </div>

                                <div className="quote-form-group">
                                    <label htmlFor="message">Mensaje</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        placeholder="Describa su proyecto o necesidades"
                                        required
                                    ></textarea>
                                </div>

                                <button type="submit" className="quote-submit-button">
                                    Continuar
                                </button>
                            </form>
                        </>
                    ) : (
                        <>
                            <div className="modal-step-header">
                                <button className="back-button" onClick={handleBack}>
                                    <FontAwesomeIcon icon={faArrowLeft} /> Volver
                                </button>
                                <h2 className="quote-modal-title">Información Adicional</h2>
                            </div>

                            <form onSubmit={handleSubmit} className="quote-modal-form">
                                <div className="quote-form-group file-upload-group">
                                    <label htmlFor="file">Adjuntar factura eléctrica</label>
                                    <input
                                        type="file"
                                        id="file"
                                        name="file"
                                        className="file-input"
                                        onChange={handleFileChange}
                                        ref={fileInputRef}
                                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                                    />
                                    <p className="file-help">Formatos permitidos: PDF, DOC, DOCX, JPG, PNG</p>

                                    {filePreview && (
                                        <div className="file-preview">
                                            {filePreview.type === 'image' ? (
                                                <>
                                                    <img src={filePreview.url} alt="Preview" className="image-preview" />
                                                    <p><FontAwesomeIcon icon={faImage} /> {filePreview.name}</p>
                                                </>
                                            ) : (
                                                <p><FontAwesomeIcon icon={faFile} /> {filePreview.name}</p>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className="quote-form-group location-group">
                                    <label>Ubicación del Proyecto</label>
                                    <div className="location-type-selector">
                                        <button
                                            type="button"
                                            className={`location-type-button ${locationType === 'current' ? 'active' : ''}`}
                                            onClick={() => handleLocationTypeChange('current')}
                                        >
                                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                                            Ubicación Actual
                                        </button>
                                        <button
                                            type="button"
                                            className={`location-type-button ${locationType === 'manual' ? 'active' : ''}`}
                                            onClick={() => handleLocationTypeChange('manual')}
                                        >
                                            <FontAwesomeIcon icon={faLocationDot} />
                                            Dirección Manual
                                        </button>
                                    </div>

                                    {locationType === 'current' ? (
                                        <div className="current-location-section">
                                            <button
                                                type="button"
                                                className="location-button"
                                                onClick={handleGetLocation}
                                                disabled={isLoading}
                                            >
                                                <FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon" />
                                                {isLoading ? 'Obteniendo ubicación...' : 'Compartir ubicación actual'}
                                            </button>
                                            {coordinates && (
                                                <div className="coordinates-display">
                                                    <p>Ubicación obtenida con éxito</p>
                                                    <small>
                                                        Lat: {coordinates.latitude.toFixed(6)},
                                                        Lon: {coordinates.longitude.toFixed(6)}
                                                    </small>
                                                </div>
                                            )}
                                            {locationError && (
                                                <p className="location-error">{locationError}</p>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="manual-address-section">
                                            <div className="quote-form-group">
                                                <label htmlFor="street">Calle</label>
                                                <input
                                                    type="text"
                                                    id="street"
                                                    value={manualAddress.street}
                                                    onChange={(e) => handleAddressChange('street', e.target.value)}
                                                    placeholder="Nombre de la calle y número"
                                                    required={locationType === 'manual'}
                                                />
                                            </div>
                                            <div className="quote-form-group">
                                                <label htmlFor="sector">Sector</label>
                                                <input
                                                    type="text"
                                                    id="sector"
                                                    value={manualAddress.sector}
                                                    onChange={(e) => handleAddressChange('sector', e.target.value)}
                                                    placeholder="Sector o barrio"
                                                    required={locationType === 'manual'}
                                                />
                                            </div>
                                            <div className="quote-form-group">
                                                <label htmlFor="city">Ciudad</label>
                                                <input
                                                    type="text"
                                                    id="city"
                                                    value={manualAddress.city}
                                                    onChange={(e) => handleAddressChange('city', e.target.value)}
                                                    placeholder="Ciudad"
                                                    required={locationType === 'manual'}
                                                />
                                            </div>
                                            <div className="quote-form-group">
                                                <label htmlFor="reference">Punto de referencia (opcional)</label>
                                                <input
                                                    type="text"
                                                    id="reference"
                                                    value={manualAddress.reference}
                                                    onChange={(e) => handleAddressChange('reference', e.target.value)}
                                                    placeholder="Ej: Cerca del parque central"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <button type="submit" className="quote-submit-button">
                                    Solicitar Cotización
                                </button>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

MapLocationPicker.propTypes = {
    onLocationSelect: PropTypes.func.isRequired,
};

QuoteModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default QuoteModal;