import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './pages/about/Nosotros.jsx';
import Gallery from './pages/gallery/Galeria.jsx';
import Home from './pages/homepage/Home.jsx';
import NotFound from './pages/notfound/NotFound.jsx';
import Footer from './components/footer/Footer.jsx';
import Contact from './pages/contact/Contact';
import ContactModal from './components/cta-modal/ContactModal';
import QuoteModal from './components/quote-modal/QuoteModal';
import WhatsAppButton from './components/whatsapp/WhatsAppButton.jsx';
import { useState, createContext } from 'react';
import './App.css';

// Create a context to manage modals throughout the app
export const ModalContext = createContext();

const App = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [showQuoteModal, setShowQuoteModal] = useState(false);

  const handleShowContactModal = () => {
    setShowContactModal(true);
    setShowQuoteModal(false); // Ensure other modal is closed
  };

  const handleCloseContactModal = () => {
    setShowContactModal(false);
  };

  const handleShowQuoteModal = () => {
    setShowQuoteModal(true);
    setShowContactModal(false);
  };

  const handleCloseQuoteModal = () => {
    setShowQuoteModal(false);
  };

  const modalContextValue = {
    showContactModal: handleShowContactModal,
    showQuoteModal: handleShowQuoteModal,
    isAnyModalOpen: showContactModal || showQuoteModal // Add this to context
  };

  return (
    <Router>
      <ModalContext.Provider value={modalContextValue}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nosotros" element={<About />} />
          <Route path="/proyectos" element={<Gallery />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer onShowModal={handleShowContactModal} />
        <ContactModal
          show={showContactModal}
          onClose={handleCloseContactModal}
        />
        <QuoteModal
          show={showQuoteModal}
          onClose={handleCloseQuoteModal}
        />
        <WhatsAppButton isHidden={showContactModal || showQuoteModal} />
      </ModalContext.Provider>
    </Router>
  );
};

export default App;