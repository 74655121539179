import { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TESTIMONIALS } from './constants';
import CarouselControls from '../carousel-controls/CarouselControls';
import RectangleCard from '../rectangle-card/RectangleCard';
import 'swiper/css';
import './TestimonialsCarousel.css';

const TestimonialsCarousel = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [slidesPerView, setSlidesPerView] = useState(getSlidesPerView());
    const swiperRef = useRef(null);

    function getSlidesPerView() {
        const width = window.innerWidth;
        if (width <= 768) return 1;
        return 2;
    }

    const totalPages = Math.ceil(TESTIMONIALS.length / slidesPerView);

    useEffect(() => {
        const handleResize = () => {
            const newIsMobile = window.innerWidth <= 768;
            setIsMobile(newIsMobile);
            const newSlidesPerView = getSlidesPerView();
            setSlidesPerView(newSlidesPerView);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        if (swiperRef.current && swiperRef.current.swiper) {
            const slideIndex = newPage * slidesPerView;
            swiperRef.current.swiper.slideTo(slideIndex);
        }
    };

    const handleSlideChange = (swiper) => {
        const newPage = Math.floor(swiper.realIndex / slidesPerView);
        if (newPage !== currentPage) {
            setCurrentPage(newPage);
        }
    };

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return (
        <div className="testimonials-section d-flex align-items-center justify-content-center">
            <div className="container d-flex flex-wrap">
                <div className="testimonials-carousel-content">
                    <div className="testimonials-carousel-header">
                        <h2 className="section-heading text-left">
                            Testimonios de nuestros clientes
                        </h2>
                        {!isMobile && (
                            <CarouselControls
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                                lightTheme={true}
                            />
                        )}
                    </div>
                    <div
                        className="testimonials-carousel-container"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Swiper
                            ref={swiperRef}
                            slidesPerView={slidesPerView}
                            spaceBetween={30}
                            loop={true}
                            onSlideChange={handleSlideChange}
                            autoplay={!isHovered ? {
                                delay: 5000,
                                disableOnInteraction: false,
                            } : false}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                    touchRatio: 1,
                                    shortSwipes: true,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                    touchRatio: 1,
                                    shortSwipes: true,
                                }
                            }}
                        >
                            {TESTIMONIALS.map((testimonial) => (
                                <SwiperSlide key={testimonial.id}>
                                    <RectangleCard
                                        title={testimonial.title}
                                        content={testimonial.body}
                                        user={testimonial.clientName}
                                        work={testimonial.clientWork}
                                        content2={testimonial.metric}
                                        numberPorcentage={testimonial.percentage}
                                        colors={{
                                            background: '#fff',
                                            text: '#000',
                                            border: '#ccc'
                                        }}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialsCarousel;