import ID1 from "../../assets/ID-1.webp"
import ID2 from "../../assets/ID-2.webp"
import ID3 from "../../assets/ID-3.webp"
import ID4 from "../../assets/ID-4.webp"
import ID5 from "../../assets/ID-5.webp"
import ID6 from "../../assets/ID-6.webp"
import ID7 from "../../assets/ID-7.webp"
import ID8 from "../../assets/ID-8.webp"
import ID9 from "../../assets/ID-9.webp"
import ID10 from "../../assets/ID-10.webp"
import ID11 from "../../assets/ID-11.webp"
import ID12 from "../../assets/ID-12.webp"

export const GALLERY_PROJECTS = [
    {
        id: '1',
        image: ID1,
        title: 'Clínica Conductual Volver',
        subtitle: 'Municipio Consuelo',
        description: 'Con una potencia instalada de 52.32 kwp, este proyecto está generando un increíble ahorro anual de RD$1,021,405.27. Cada vez más personas y empresas reconocen el poder de la energía solar para reducir significativamente el costo de su factura eléctrica.',
        tags: ['Proyecto corporativo']
    },
    {
        id: '2',
        image: ID2,
        title: 'Proyecto Residencial en La Vega',
        subtitle: 'La Vega',
        description: 'Este proyecto de inyección a la red desarrollado en La Vega cuenta con una potencia de 6.6 kWp, permitiendo a nuestro cliente ahorrar anualmente 125,940 pesos de factura eléctrica y teniendo un rápido retorno de su inversión en tan solo 2.8 años.',
        tags: ['Proyecto residencial']
    },
    {
        id: '3',
        image: ID3,
        title: 'Galinca Industrial',
        subtitle: 'Distrito Nacional',
        description: 'Con una potencia instalada de 101 kWp ayudamos a Galinca Industrial a ahorrar anualmente 1,077, 024 pesos, reduciendo significativamente sus costos operativos, potenciando su productividad y generando un impacto positivo en el medioambiente.',
        tags: ['Proyecto industrial']
    },
    {
        id: '4',
        image: ID4,
        title: 'Proyecto Residencial en el Distrito Nacional',
        subtitle: 'Distrito Nacional',
        description: 'Con esta instalación de 23 kwp nuestro cliente residencial logrará producir al año 35,597.6 kwh que suplirán la totalidad de su consumo energético, generando un ahorro de 440,120 pesos anuales y recibiendo los incentivos económicos de la Ley 57-07.',
        tags: ['Proyecto residencial']
    },
    {
        id: '5',
        image: ID5,
        title: 'Ferretería Codeca Autopista Duarte',
        subtitle: 'Distrito Nacional',
        description: 'Con 136.3 kWp de potencia instalada, este proyecto producirá anualmente 215,667.4 kWh de energía limpia, generando un ahorro de 1,969,039.71 pesos al año. Nos enorgullece ser partícipes de la transición de las empresas hacia la sostenibilidad y la independencia energética.',
        tags: ['Proyecto corporativo']
    },
    {
        id: '6',
        image: ID6,
        title: 'Oficinas Administrativas PISA',
        subtitle: 'Haina',
        description: 'Con una potencia de 37.18 kWp y una producción energética anual de 54,582 kWh, esta instalación permitirá el ahorro del 100% del consumo energético de nuestro cliente industrial, beneficiando el desarrollo de sus operaciones al reducir sus gastos económicos.',
        tags: ['Proyecto corporativo']
    },
    {
        id: '7',
        image: ID7,
        title: 'Proyecto Residencial en el Distrito Nacional',
        subtitle: 'Distrito Nacional',
        description: 'Con 21 paneles solares marca Canadian Solar de 545W y un inversor Fronius de 8.2kw, este proyecto genera una potencia de 11.45 kWp que cubre las necesidades energéticas de nuestro cliente y le posibilita un ahorro anual de 218,392 pesos.',
        tags: ['Proyecto residencial']
    },
    {
        id: '8',
        image: ID8,
        title: 'Moldeados Dominicanos S.A',
        subtitle: 'Distrito Nacional',
        description: 'Proyecto hecho para Total Energies en las instalaciones de Moldeados Dominicanos. Gracias a la luz solar captada por los 1,840 paneles solares de 545 watts colocados, un megawatt de potencia (1 mWp) cubrirá la demanda energética generada por las actividades productivas de la planta, sobrando un excedente que podrá ser inyectado a la red y que será para nuestro cliente un ingreso, de acuerdo a lo dispuesto por la ley 57-07 de energía renovable.',
        tags: ['Proyecto industrial']
    },
    {
        id: '9',
        image: ID9,
        title: 'Proyecto Residencial en San Pedro de Macorís',
        subtitle: 'San Pedro de Macorís',
        description: 'Sistema fotovoltaico híbrido con 8.7 kwp de potencia instalada y capacidad de almacenamiento de 13.2 kwh diarios en baterías de lithium. Con este sistema nuestro cliente puede utilizar en el día la energía generada por sus módulos fotovoltaicos y en la noche tomar la energía almacenada en las baterías, evitando utilizar la energía eléctrica de la red pública en horas nocturnas..',
        tags: ['Proyecto residencial']
    },
    {
        id: '10',
        image: ID10,
        title: 'Total Energies Estación Jacobo Majluta',
        subtitle: 'Distrito Nacional',
        description: 'Celebramos otro proyecto exitoso de 65.4 kWp instalados, potencia que permitirá a nuestro cliente ahorrar 766,893.92 pesos anualmente y recuperar su inversión en solo 3.5 años. Cada rayo de sol se convierte en beneficios económicos tangibles.',
        tags: ['Proyecto industrial']
    },
    {
        id: '11',
        image: ID11,
        title: 'Ferretería Beato',
        subtitle: 'Distrito Nacional',
        description: 'La familia Beato viste otra de sus prestigiosas ferreterías con nuestros paneles, los resultados del ahorro energético hacen de sus negocios mucho más competitivos, aportando responsabilidad medioambiental en sus instalaciones. Ferretería Beato  estará ahorrando 1,262,803 $RD anual y reduciendo 26,840.64 kg de CO2.',
        tags: ['Proyecto corporativo']
    },
    {
        id: '12',
        image: ID12,
        title: 'Ferretería Codeca Pantoja',
        subtitle: 'Pantoja',
        description: 'Gracias a esta poderosa instalación de 109 kWp, los recursos económicos que antes nuestro cliente industrial debía invertir en su alto consumo energético, ahora le es posible reinvertirlos en el crecimiento de otras áreas.',
        tags: ['Proyecto corporativo']
    }
];