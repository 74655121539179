import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import GalleryModal from '../gallery-modal/GalleryModal';
import './Gallery.css';

const Gallery = ({ projects, }) => {
    const location = useLocation();
    const [selectedProject, setSelectedProject] = useState(location.state?.selectedProject || null);
    const [showModal, setShowModal] = useState(!!location.state?.selectedProject);
    const [searchTerm, setSearchTerm] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const [locationFilter, setLocationFilter] = useState('');
    const [tagFilter, setTagFilter] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [filteredProjects, setFilteredProjects] = useState(projects);

    const locations = ['Distrito Nacional', 'La Vega', 'Municipio Consuelo', 'Haina', 'San Pedro de Macorís', 'Pantoja' ];
    const orderOptions = ['Más recientes', 'Más antiguos', 'A-Z', 'Z-A',];

    useEffect(() => {
        filterProjects();
    }, [searchTerm, locationFilter, orderBy, tagFilter, projects]);

    const openModal = (project) => {
        setSelectedProject(project);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const filterProjects = () => {
        let filtered = projects.filter(project =>
            (project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                project.subtitle.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (locationFilter === '' || project.location === locationFilter) &&
            (tagFilter === '' || project.tags.includes(tagFilter))
        );

        if (orderBy) {
            switch (orderBy) {
                case 'Más recientes':
                    filtered.sort((a, b) => b.id - a.id);
                    break;
                case 'Más antiguos':
                    filtered.sort((a, b) => a.id - b.id);
                    break;
                case 'A-Z':
                    filtered.sort((a, b) => a.title.localeCompare(b.title));
                    break;
                case 'Z-A':
                    filtered.sort((a, b) => b.title.localeCompare(a.title));
                    break;
                default:
                    break;
            }
        }

        setFilteredProjects(filtered);
    };

    return (
        <div className="gallery-container">
            <div className="controls">
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Buscar..."
                        onChange={(e) => handleSearch(e.target.value)}
                        className="search-input"
                    />
                </div>
                <div className="filter-container">
                    <button onClick={toggleFilters} className="filter-button sucess-btn btn">
                        Filtrar por <i className="fas fa-caret-down"></i>
                    </button>
                    {showFilters && (
                        <div className="filter-popup">
                            <div className="filter-popup-arrow"></div>
                            <h3>Filtros</h3>
                            <div className="select-wrapper">
                                <select
                                    value={locationFilter}
                                    onChange={(e) => setLocationFilter(e.target.value)}
                                    className="filter-select"
                                >
                                    <option value="">Localizacion</option>
                                    {locations.map(loc => (
                                        <option key={loc} value={loc}>{loc}</option>
                                    ))}
                                </select>
                                <i className="fas fa-caret-down"></i>
                            </div>
                            <div className="select-wrapper">
                                <select
                                    value={tagFilter}
                                    onChange={(e) => setTagFilter(e.target.value)}
                                    className="filter-select"
                                >
                                    <option value="">Etiqueta</option>
                                    {Array.from(new Set(projects.flatMap(project => project.tags))).map(tag => (
                                        <option key={tag} value={tag}>{tag}</option>
                                    ))}
                                </select>
                                <i className="fas fa-caret-down"></i>
                            </div>
                            <div className="select-wrapper">
                                <select
                                    value={orderBy}
                                    onChange={(e) => setOrderBy(e.target.value)}
                                    className="filter-select"
                                >
                                    <option value="">Ordenar por</option>
                                    {orderOptions.map(option => (
                                        <option key={option} value={option}>{option}</option>
                                    ))}
                                </select>
                                <i className="fas fa-caret-down"></i>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="grid-container">
                {filteredProjects.length > 0 ? (
                    filteredProjects.map((project) => (
                        <div key={project.id} className="project-card" onClick={() => openModal(project)}>
                            <img src={project.image} alt={project.title} className="project-image" />
                            <div className="project-info">
                                <h3 className="project-title">{project.title}</h3>
                                <p className="project-subtitle">{project.subtitle}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="no-results">No se encontraron resultados para `&quot;`{searchTerm}`&quot;`</div>
                )}
            </div>
            <GalleryModal project={selectedProject} show={showModal} onClose={closeModal} />
        </div>
    );
};

Gallery.propTypes = {
    projects: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            subtitle: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            location: PropTypes.string.isRequired,
            tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        })
    ).isRequired,
    onSearch: PropTypes.func.isRequired,
};

export default Gallery;