export const faqData = [
    {
        question: "¿Cómo me beneficia la energía solar?",
        answer: `
            La energía solar ofrece múltiples beneficios tanto a nivel individual como comunitario y ambiental.
            <br><br>
            <ul>
                <li>Reducción de tu factura eléctrica hasta cero pesos, mientras cubres las necesidades energéticas de tu hogar o empresa de manera parcial o total. Asimismo, recibes los incentivos económicos de la Ley 57-07 si cuentas con una instalación fotovoltaica de inyección a la red.</li>
                <li>Menor dependencia de la red eléctrica y protección contra aumentos futuros en los costos de electricidad.</li>
                <li>Reducción de la huella de carbono y conservación de recursos naturales al usar una fuente de energía limpia y renovable.</li>
            </ul>
        `
    },
    {
        question: "¿Cuánto cuesta instalar paneles solares?",
        answer: `
            El costo de instalar paneles solares puede variar dependiendo de varios factores, como el tamaño del sistema (que depende en gran medida de tu rango de consumo energético), la complejidad de la instalación, la ubicación geográfica, el tipo de sistema fotovoltaico (inyección a la red, híbrido, aislado), etc.
        `
    },
    {
        question: "¿Cuál es la garantía y tiempo de vida de una instalación de paneles solares?",
        answer: "En Enerting, ofrecemos paneles solares con garantía de producto de 25 años y garantía de rendimiento de 25-30 años, asegurando entre el 80% y el 90% de su capacidad original. Los inversores tienen una garantía de 5-10 años, con opciones de extensión hasta 20 años. La vida útil de los paneles solares es de 25-30 años o más, y los inversores pueden necesitar mantenimiento o reemplazo durante este tiempo. Nos comprometemos a proporcionarte productos de alta calidad y soporte continuo para que disfrutes de la energía solar sin preocupaciones."
    },
    {
        question: "¿Ofrecen financiamiento?",
        answer: `
            A través de los bancos Popular, Santa Cruz y Banreservas puedes adquirir un financiamiento para la instalación de tu sistema fotovoltaico.
            Estas opciones de financiamiento aplican tanto para clientes individuales como para clientes comerciales.
        `
    },
    {
        question: "¿Por qué elegir a Enerting?",
        answer: `
            Enerting utiliza tecnologías avanzadas para asegurar que nuestras soluciones operen de manera correcta y eficiente. Nuestro principal objetivo es la satisfacción de nuestros clientes.
        `
    }
];
