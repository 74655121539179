import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './CallToAction.css';

const CallToAction = ({ title, text, buttonText, svgSource, onButtonClick }) => {
    return (
        <div className="cta-wrapper">
            <div className="cta-container">
                <div className="cta-content">
                    <div className="cta-text">
                        <h2>{title}</h2>
                        <hr className="cta-hr" />
                        <p>{text}</p>
                    </div>
                    <Button
                        onClick={onButtonClick}
                        variant="light"
                        className="cta-button"
                    >
                        {buttonText}
                    </Button>
                </div>
                {svgSource && (
                    <div className="svg-cta">
                        <img src={svgSource} alt="Decorative illustration" />
                    </div>
                )}
            </div>
        </div>
    );
};

CallToAction.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    svgSource: PropTypes.string,
    onButtonClick: PropTypes.func.isRequired,
};

CallToAction.defaultProps = {
    svgSource: null,
};

export default CallToAction;