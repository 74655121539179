import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import bgImage from '../../assets/bg-image.webp';
import Footer from '../../components/footer/Footer';
import NavBar from '../../components/navbar/NavBar';
import './NotFound.css';

const NotFound = () => {
    const navigate = useNavigate();
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = bgImage;
        img.onload = () => setImageLoaded(true);
    }, []);

    return (
        <>
            <NavBar isNavbarTransparent={true} />
            <div className={`not-found-section ${imageLoaded ? 'image-loaded' : ''}`}>
                <img
                    src={bgImage}
                    alt=""
                    className="not-found-background"
                    loading="eager"
                />
                <div className="container">
                    <div className="not-found-content">
                        <h1>404</h1>
                        <h2>Página no encontrada</h2>
                        <p>Lo sentimos, la página que estás buscando no existe o ha sido movida.</p>
                        <div className="not-found-buttons">
                            <Button
                                onClick={() => navigate('/')}
                                className="home-button"
                                variant="success"
                            >
                                Volver al inicio
                            </Button>
                            <Button
                                onClick={() => navigate(-1)}
                                className="back-button"
                                variant="success"
                            >
                                Página anterior
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFound;