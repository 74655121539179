import React from 'react';
import PropTypes from 'prop-types';
import './Certificates.css';

const Certificates = ({ imageSrc, text }) => {
    return (
        <div className="certificates-container">
            <img src={imageSrc} alt="Certificate" className="certificates-image" />
            <p className="certificates-text">{text}</p>
        </div>
    );
};

Certificates.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default Certificates;
