export const TESTIMONIALS = [
    {
        id: 1,
        title: "Potencia Solar, Ahorro Real",
        body: "Muy satisfecho con la calidad del servicio y la transparencia en la comunicación. Desde la consulta inicial hasta la puesta en marcha del sistema, cada paso fue explicado claramente y se me mantuvo informado en todo momento. La instalación fue rápida y el rendimiento de los paneles ha superado nuestras expectativas. ¡Altamente recomendable!",
        clientName: "Yovanny Beato",
        clientWork: "Presidente de Beato Abastecimientos Ferreteros",
        clientImage: "/path/to/ramon-vasquez.jpg",
        metric: "Ahorro en Costos",
        percentage: 55
    },
    {
        id: 2,
        title: "Sostenibilidad a Tu Alcance",
        body: "Como ingeniera, tenía expectativas altas y puedo decir que fueron superadas en todos los aspectos. El equipo demostró un profundo conocimiento técnico y brindó soluciones innovadoras para optimizar la eficiencia energética. El sistema está funcionando a la perfección y ya estamos viendo un ahorro significativo!",
        clientName: "Ing. Martha Valenzuela",
        clientWork: "Gerente de Proyectos de ADESA",
        clientImage: "/path/to/eduardo-perez.jpg",
        metric: "Ahorro en Costos",
        percentage: 98
    },
    {
        id: 3,
        title: "Eficiencia Energética Maximizada",
        body: "Excelente servicio pre-venta y post-venta. El proceso de instalación del sistema fue rápido y tomaron mucho cuidado con el impermeabilizante del techo.",
        clientName: "Riccardo Seravalle",
        clientWork: "Gerente Operaciones e Ingeniería de Latin American Free Zone Investments Dominicana",
        clientImage: "/path/to/maria-gonzalez.jpg",
        metric: "Ahorro en Costos",
        percentage: 40
    },
    {
        id: 4,
        title: "Innovación Solar para Negocios",
        body: "Enerting revolucionó nuestra forma de pensar sobre la energía empresarial. Su solución personalizada se adaptó perfectamente a nuestras necesidades, resultando en ahorros sustanciales y una imagen corporativa mejorada. El retorno de inversión ha sido impresionante.",
        clientName: "Carlos Mendoza",
        clientWork: "Director Financiero",
        clientImage: "/path/to/carlos-mendoza.jpg",
        metric: "Ahorro en Costos",
        percentage: 75
    },
    {
        id: 5,
        title: "Transición Energética",
        body: "Temía que cambiar a energía solar sería complicado, pero Enerting hizo todo el proceso increíblemente fácil. Desde la planificación hasta la instalación, su equipo fue profesional y eficiente. Ahora disfruto de facturas de electricidad más bajas y un negocio más sostenible.",
        clientName: "Ana Martínez",
        clientWork: "Propietaria de Pequeña Empresa",
        clientImage: "/path/to/ana-martinez.jpg",
        metric: "Ahorro en Costos",
        percentage: 35
    },
    {
        id: 6,
        title: "Impacto Ambiental Positivo",
        body: "Gracias a Enerting, nuestra comunidad ha dado un paso gigante hacia la sostenibilidad. Su proyecto de energía solar comunitaria no solo redujo nuestras facturas, sino que también inspiró a otros a unirse al movimiento verde. El impacto en nuestra huella de carbono ha sido significativo.",
        clientName: "Jorge Sánchez",
        clientWork: "Líder Comunitario",
        clientImage: "/path/to/jorge-sanchez.jpg",
        metric: "Ahorro en Costos",
        percentage: 25
    }
];