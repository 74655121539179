import PropTypes from 'prop-types';
import './CarouselControls.css';

const CarouselControls = ({ totalPages, currentPage, onPageChange, lightTheme }) => {
    const handleKeyDown = (event) => {
        switch (event.key) {
            case 'ArrowRight':
                event.preventDefault();
                onPageChange(currentPage === totalPages - 1 ? 0 : currentPage + 1);
                break;
            case 'ArrowLeft':
                event.preventDefault();
                onPageChange(currentPage === 0 ? totalPages - 1 : currentPage - 1);
                break;
            default:
                break;
        }
    };

    return (
        <div
            className={`carousel-controls ${lightTheme ? 'light-theme' : ''}`}
            role="tablist"
            aria-label="Carousel navigation"
            onKeyDown={handleKeyDown}
        >
            {Array.from({ length: totalPages }).map((_, index) => (
                <button
                    key={index}
                    role="tab"
                    aria-selected={index === currentPage}
                    aria-label={`Go to slide ${index + 1} of ${totalPages}`}
                    aria-controls={`carousel-item-${index}`}
                    className={`carousel-dot ${index === currentPage ? 'active' : ''}`}
                    onClick={() => onPageChange(index)}
                    tabIndex={index === currentPage ? 0 : -1}
                >
                    <span className="carousel-dot-inner" aria-hidden="true"></span>
                </button>
            ))}
        </div>
    );
};

CarouselControls.propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    lightTheme: PropTypes.bool,
};

CarouselControls.defaultProps = {
    lightTheme: false,
};

export default CarouselControls;