import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import './WhatsAppButton.css'

const WhatsAppButton = ({ isHidden }) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.target.style.overflow === 'hidden') {
                    setIsModalOpen(true);
                } else {
                    setIsModalOpen(false);
                }
            });
        });

        observer.observe(document.body, {
            attributes: true,
            attributeFilter: ['style']
        });

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (isModalOpen) return;

        const showTimer = setTimeout(() => {
            setIsTooltipVisible(true);
        }, 2000);

        const hideTimer = setTimeout(() => {
            setIsTooltipVisible(false);
        }, 6000);

        return () => {
            clearTimeout(showTimer);
            clearTimeout(hideTimer);
        };
    }, [isModalOpen]);

    useEffect(() => {
        if (isHidden || isModalOpen) {
            setIsTooltipVisible(false);
        }
    }, [isHidden, isModalOpen]);

    const handleWhatsAppClick = () => {
        window.open('https://wa.me/18097482828?text=Hola%2C%20me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios.', '_blank');
    };

    if (isHidden || isModalOpen) return null;

    return (
        <motion.div
            className="whatsapp-container"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
                type: "spring",
                stiffness: 260,
                damping: 20
            }}
        >
            <AnimatePresence>
                {isTooltipVisible && (
                    <motion.div
                        className="whatsapp-tooltip"
                        initial={{ opacity: 0, x: -10, scale: 0.95 }}
                        animate={{
                            opacity: 1,
                            x: 0,
                            scale: 1,
                            transition: {
                                type: "spring",
                                stiffness: 500,
                                damping: 30
                            }
                        }}
                        exit={{
                            opacity: 0,
                            x: -10,
                            scale: 0.95,
                            transition: {
                                duration: 0.2,
                                ease: "easeOut"
                            }
                        }}
                        onClick={() => setIsTooltipVisible(false)}
                    >
                        <p>Conversemos. ¡Estamos listos para asistirte!</p>
                        <div className="tooltip-arrow" />
                    </motion.div>
                )}
            </AnimatePresence>

            <motion.button
                className="whatsapp-button"
                whileHover={{
                    scale: 1.1,
                    backgroundColor: "#1faa52",
                    transition: { duration: 0.2 }
                }}
                whileTap={{ scale: 0.95 }}
                onMouseEnter={() => !isTooltipVisible && setIsTooltipVisible(true)}
                onMouseLeave={() => setIsTooltipVisible(false)}
                onClick={handleWhatsAppClick}
                aria-label="Contactar por WhatsApp"
            >
                <motion.div
                    className="pulse-ring"
                    animate={{
                        scale: [1, 1.5, 1],
                        opacity: [0.25, 0, 0.25]
                    }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut"
                    }}
                />

                <div className="button-content">
                    <FontAwesomeIcon
                        icon={faWhatsapp}
                        className="whatsapp-icon"
                    />
                </div>
            </motion.button>
        </motion.div>
    );
};

WhatsAppButton.propTypes = {
    isHidden: PropTypes.bool
};

WhatsAppButton.defaultProps = {
    isHidden: false
};

export default WhatsAppButton;