import PropTypes from 'prop-types';
import './GalleryModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

const GalleryModal = ({ project, show, onClose }) => {
    if (!project || !show) return null;

    const handleOverlayClick = (e) => {
        if (e.target.className === 'modal-overlay') {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close-button" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className="modal-left">
                    <h2 className="modal-title">{project.title}</h2>
                    <p className="modal-subtitle">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon" />
                        {project.subtitle}
                    </p>
                    <div className="modal-tags">
                        {project.tags.map((tag, index) => (
                            <span key={index} className="modal-tag">{tag}</span>
                        ))}
                    </div>
                    <p className="modal-description">{project.description}</p>
                </div>
                <div className="modal-right">
                    <img src={project.image} alt={project.title} className="modal-main-image" />
                </div>
            </div>
        </div>
    );
};

GalleryModal.propTypes = {
    project: PropTypes.shape({
        id: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default GalleryModal;