import { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { ModalContext } from '../../App';
import bgImage800 from '../../assets/bg-image-800.webp';
import bgImage1200 from '../../assets/bg-image-1200.webp';
import bgImage1600 from '../../assets/bg-image-1600.webp';
import bgImage2400 from '../../assets/bg-image-2400.webp';
import { tinyPlaceholder } from '../../assets/tiny-placeholder';
import { motion, useAnimation } from 'framer-motion';
import './Hero.css';

const Hero = () => {
    const { showQuoteModal } = useContext(ModalContext);
    const controls = useAnimation();

    useEffect(() => {
        controls.start('visible');
    }, [controls]);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.2,
                duration: 0.3,
                ease: "easeOut"
            }
        }
    };

    const itemVariants = {
        hidden: {
            opacity: 0,
            y: 20
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                ease: [0.43, 0.13, 0.23, 0.96] // Adjusted cubic-bezier
            }
        }
    };

    const buttonVariants = {
        hidden: {
            opacity: 0,
            scale: 0.8
        },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.5,
                ease: [0.43, 0.13, 0.23, 0.96] // Adjusted cubic-bezier
            }
        },
        hover: {
            scale: 1.05,
            transition: {
                duration: 0.2,
                ease: "easeOut"
            }
        },
        tap: {
            scale: 0.95
        }
    };

    return (
        <section className="hero-section">
            {/* Tiny placeholder image */}
            <img
                src={tinyPlaceholder}
                alt=""
                className="hero-background placeholder"
                aria-hidden="true"
                width="100%"
                height="110%"
            />

            {/* Main hero image */}
            <img
                src={bgImage1200}
                srcSet={`
                    ${bgImage800} 800w,
                    ${bgImage1200} 1200w,
                    ${bgImage1600} 1600w,
                    ${bgImage2400} 2400w
                `}
                sizes="(max-width: 800px) 800px,
                       (max-width: 1200px) 1200px,
                       (max-width: 1600px) 1600px,
                       2400px"
                alt=""
                className="hero-background main"
                loading="eager"
                fetchpriority="high"
                decoding="async"
                width="100%"
                height="110%"
                onLoad={(e) => {
                    e.target.classList.add('loaded');
                    controls.start('visible');
                }}
            />

            {/* Overlay gradient */}
            <div className="hero-overlay" />

            {/* Content */}
            <motion.div
                className="container"
                initial="hidden"
                animate={controls}
                variants={containerVariants}
            >
                <div className="hero-content">
                    <motion.h1
                        className="hero-heading"
                        variants={itemVariants}
                    >
                        <motion.span
                            className="line1"
                            variants={itemVariants}
                        >
                            Energía Solar a
                        </motion.span>
                        <motion.span
                            className="line2"
                            variants={itemVariants}
                        >
                            Tu Alcance.
                        </motion.span>
                    </motion.h1>

                    <motion.p
                        className="hero-subtitle"
                        variants={itemVariants}
                    >
                        <motion.span className="line1">
                            Lleva la energía solar a tu hogar o negocio
                        </motion.span>
                    </motion.p>

                    <motion.div variants={buttonVariants}>
                        <Button
                            as={motion.button}
                            onClick={showQuoteModal}
                            className="hero-cta-button"
                            variant="success"
                            variants={buttonVariants}
                            whileHover="hover"
                            whileTap="tap"
                            initial="hidden"
                            animate="visible"
                        >
                            Solicitar Cotización
                        </Button>
                    </motion.div>
                </div>
            </motion.div>
        </section>
    );
};

export default Hero;