import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GALLERY_PROJECTS } from '../../pages/gallery/constants';
import CarouselControls from '../carousel-controls/CarouselControls';
import 'swiper/css';
import './ProjectCarousel.css';

const ProjectCarousel = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [slidesPerView, setSlidesPerView] = useState(getSlidesPerView());
    const navigate = useNavigate();
    const swiperRef = useRef(null);
    const maxProjects = 12;
    const limitedProjects = GALLERY_PROJECTS.slice(0, maxProjects);

    function getSlidesPerView() {
        const width = window.innerWidth;
        if (width < 768) return 1;
        if (width < 1024) return 2;
        if (width < 1200) return 3;
        return 4;
    }

    const totalPages = Math.ceil(limitedProjects.length / slidesPerView);

    useEffect(() => {
        const handleResize = () => {
            const newIsMobile = window.innerWidth <= 768;
            setIsMobile(newIsMobile);
            const newSlidesPerView = getSlidesPerView();
            setSlidesPerView(newSlidesPerView);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        if (swiperRef.current && swiperRef.current.swiper) {
            const slideIndex = newPage * slidesPerView;
            swiperRef.current.swiper.slideTo(slideIndex);
        }
    };

    const handleSlideChange = (swiper) => {
        const newPage = Math.floor(swiper.realIndex / slidesPerView);
        if (newPage !== currentPage) {
            setCurrentPage(newPage);
        }
    };

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const handleProjectClick = (project) => {
        navigate('/proyectos', { state: { selectedProject: project } });
    };

    return (
        <div className="project-carousel">
            <div className="container">
                <div className="project-carousel-header">
                    <h2 className="section-heading">Nuestros proyectos</h2>
                    {!isMobile && (
                        <CarouselControls
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    )}
                </div>
                <div
                    className="project-carousel-container"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Swiper
                        ref={swiperRef}
                        slidesPerView={slidesPerView}
                        spaceBetween={20}
                        loop={true}
                        onSlideChange={handleSlideChange}
                        autoplay={!isHovered ? {
                            delay: 5000,
                            disableOnInteraction: false,
                        } : false}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                                touchRatio: 1,
                                shortSwipes: true,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                                touchRatio: 1,
                                shortSwipes: true,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                                touchRatio: 1,
                                shortSwipes: true,
                            },
                            1200: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                                touchRatio: 1,
                                shortSwipes: true,
                            }
                        }}
                    >
                        {limitedProjects.map((project) => (
                            <SwiperSlide key={project.id}>
                                <div
                                    className="project-carousel-item"
                                    onClick={() => handleProjectClick(project)}
                                >
                                    <img src={project.image} alt={project.title} className="project-image" />
                                    <div className="project-info">
                                        <h3 className="project-title">{project.title}</h3>
                                        <p className="project-subtitle">{project.subtitle}</p>
                                        <p className="project-description">{project.description}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default ProjectCarousel;