import BeatoLogo from './assets/Client Logo (1).webp';
import CodecaLogo from './assets/Client Logo (2).webp';
import EnnovaLogo from './assets/Client Logo (3).webp';
import PiisaLogo from './assets/Client Logo (4).webp';
import AdesaLogo from './assets/Client Logo (5).webp';
import ActisLogo from './assets/Client Logo (6).webp';
import EcoLogo from './assets/Client Logo (7).webp';
import EstanciaLogo from './assets/Client Logo (8).webp';
import KWLogo from './assets/Client Logo (9).webp';
import TotalLogo from './assets/Client Logo (10).webp';

// export const clientLogos = [
//     { src: PiisaLogo, alt: 'PIISA Industrial Park' },
//     { src: AdesaLogo, alt: 'ADESA' },
//     { src: BeatoLogo, alt: 'Beato Abastecimiento Ferretero' },
//     { src: CodecaLogo, alt: 'CODECA' },
//     { src: EnnovaLogo, alt: 'ENNOVA' },
//     { src: ActisLogo, alt: 'GRUPO ACTIS' },
// ];

export const clientLogos = [
    { src: TotalLogo, alt: 'Total Energies' },
    { src: KWLogo, alt: 'KW OrientaL' },
    { src: PiisaLogo, alt: 'Beato Abastecimiento Ferretero' },
    { src: BeatoLogo, alt: 'Beato Abastecimiento Ferretero' },
    { src: CodecaLogo, alt: 'CODECA' },
    { src: EcoLogo, alt: 'Eco Petroleo' },
];