import PropTypes from 'prop-types';
import './RectangleCard.css';

const RectangleCard = ({ icon, title, content, colors, user, work, content2, numberPorcentage }) => {
    const { background, text, border } = colors;

    return (
        <div className="rectangle-card" style={{ backgroundColor: background, color: text, borderColor: border }}>
            <div className="rectangle-card-content">
                <h3>{title}</h3>
                <p>{content}</p>
                <div className="user-info">
                    {icon && (
                        <span className="user-icon">{icon}</span>
                    )}
                    <div className="user-details">
                        <p className="user-name">{user}</p>
                        <p className="user-work">{work}</p>
                    </div>
                </div>
                <hr className="hr-divider" />
                <div className="user-details">
                    <p className="user-number">{numberPorcentage}%</p>
                    <p className="user-content2">{content2}</p>
                </div>
            </div>
        </div>
    );
};

RectangleCard.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    work: PropTypes.string.isRequired,
    content2: PropTypes.string.isRequired,
    numberPorcentage: PropTypes.number.isRequired,
    colors: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        border: PropTypes.string,
    }),
};

RectangleCard.defaultProps = {
    icon: null,
    colors: {
        background: '#fff',
        text: '#000',
        border: 'transparent',
    },
};

export default RectangleCard;