import { Container, Row, Col } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState } from 'react';
import logo from '../../assets/enerting-complete-logo-white.webp';
import ContactModal from '../cta-modal/ContactModal';
import './Footer.css';

const Footer = () => {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <footer className="footer text-white py-4">
            <Container>
                <Row>
                    <Col xs={12} md={4} className="mb-4 footer-column">
                        <img src={logo} alt="Enerting" className="img-fluid mb-3" style={{ maxHeight: '4.5rem' }} />
                        <p className='footer-slogan'>
                            Estamos comprometidos con la eficiencia energética, aplicando las más modernas tecnologías en nuestros productos y servicios.
                        </p>
                        <div className="social-icons mt-3 d-none d-md-flex">
                            <a
                                href="https://www.instagram.com/enertingrd/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-icon mr-3"
                            >
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a
                                href="https://www.facebook.com/enertingRD/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-icon"
                            >
                                <i className="fab fa-facebook"></i>
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="mb-4 footer-column" style={{ marginTop: '1.5rem' }}>
                        <h5 className="mb-3" >Información</h5>
                        <p className="mb-1">Calle Respaldo Los Robles #4,<br /> Suite 1, La Esperilla, Santo Domingo.</p>
                        <p className="mb-1">(809) 748 2828</p>
                        <p className="mb-0">info@enerting.com</p>
                    </Col>
                    <Col xs={12} md={4} className="mb-4 footer-column" style={{ marginTop: '1.5rem' }}>
                        <h5 className="mb-3">Navegación</h5>
                        <ul className="list-unstyled mb-0">
                            <li><NavLink to="/proyectos" className="text-white">Proyectos</NavLink></li>
                            <li><NavLink to="/nosotros" className="text-white">Nosotros</NavLink></li>
                            <li><a href="#" className="text-white" onClick={(e) => { e.preventDefault(); handleShowModal(); }}>Contacto</a></li>
                        </ul>
                    </Col>
                </Row>
                <div className="social-icons-mobile d-flex d-md-none justify-content-center mt-3 mb-4">
                    <a
                        href="https://www.instagram.com/enertingrd/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-icon mr-3"
                    >
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/enertingRD/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-icon"
                    >
                        <i className="fab fa-facebook"></i>
                    </a>
                </div>
                <hr />
                <Row className="align-items-center">
                    <Col xs={12} md={6} className="mb-3 mb-md-0">
                        <p className="mb-0 footer-column">&copy; 2024 Enerting.</p>
                    </Col>
                    <Col xs={12} md={6} className="text-md-right">
                        <a
                            href="https://studiowhim.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white developer-link"
                        >
                            Desarrollado por Studio Whim
                        </a>
                    </Col>
                </Row>
            </Container>
            <ContactModal show={showModal} onClose={handleCloseModal} />
        </footer>
    );
};

export default Footer;