import { useState } from 'react';
import { Container } from 'react-bootstrap';
import NavBar from '../../components/navbar/NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Enerting from '../../assets/enerting-complete-logo.webp';
import './Contact.css'

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        // Handle form submission
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div>
            <NavBar isNavbarTransparent={false} />
            <div className="contact-page">
                <Container>
                    <div className="contact-content">
                        <div className="contact-info-section">
                            <img
                                src={Enerting}
                                alt="Enerting Logo"
                                className="contact-logo"
                            />
                            <p className="contact-description">
                                Estamos comprometidos con la eficiencia energética, aplicando las
                                más modernas tecnologías en nuestros productos y servicios.
                            </p>
                            <div className="contact-details">
                                <div className="contact-detail-item">
                                    <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
                                    <span>info@enerting.com</span>
                                </div>
                                <div className="contact-detail-item">
                                    <FontAwesomeIcon icon={faPhone} className="contact-icon" />
                                    <span>+1 (809) 748 2828</span>
                                </div>
                                <div className="contact-detail-item">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
                                    <span>Calle Respaldo Los Robles #4, Suite 1, La Esperilla, Santo Domingo</span>
                                </div>
                            </div>
                        </div>

                        <div className="contact-form-section">
                            <h2 className="page-title">Contacto</h2>
                            <form onSubmit={handleSubmit} className="contact-form">
                                <div className="form-row">
                                    <div className="form-group">
                                        <label>Nombre</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="Escriba aquí"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Apellido</label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            placeholder="Escriba aquí"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Escriba aquí"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Teléfono</label>
                                        <input
                                            type="tel"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            placeholder="Escriba aquí"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Mensaje</label>
                                    <textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        placeholder="Escriba aquí"
                                        required
                                    ></textarea>
                                </div>

                                <button type="submit" className="submit-button">
                                    Enviar
                                </button>
                            </form>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default Contact;