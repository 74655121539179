import { NavLink, Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import { ModalContext } from '../../App';
import PropTypes from 'prop-types';
import './NavBar.css';
import EnertingBlack from '../../assets/enerting-complete-logo-black.webp';
import EnertingWhite from '../../assets/enerting-complete-logo-white.webp';

const NavBar = ({ isNavbarTransparent }) => {
    const [expanded, setExpanded] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const location = useLocation();
    const { showContactModal, showQuoteModal } = useContext(ModalContext);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            if (window.innerWidth > 768) {
                setExpanded(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        setExpanded(false);
    }, [location]);

    const getButtonText = (type) => {
        if (screenWidth <= 768) {
            return type === 'contact' ? 'Contacto' : 'Cotizar';
        } else if (screenWidth <= 1199) {
            return type === 'contact' ? 'Contacto' : 'Cotizar';
        }
        return type === 'contact' ? 'Contactanos' : 'Solicitar Cotización';
    };

    const getLogoHeight = () => {
        if (screenWidth <= 320) return 30;
        if (screenWidth <= 576) return 35;
        if (screenWidth <= 768) return 40;
        return 50;
    };

    const getLogo = () => {
        if (screenWidth <= 768) {
            return EnertingWhite;
        }
        return isNavbarTransparent ? EnertingBlack : EnertingWhite;
    };

    const handleToggle = () => {
        if (screenWidth <= 768) {
            setExpanded(!expanded);
        }
    };

    const buttonClasses = {
        contact: `btn btn-outline${screenWidth <= 768 ? ' btn-mobile' : ''}`,
        quote: `btn btn-success${screenWidth <= 768 ? ' btn-mobile' : ''}`
    };

    return (
        <Navbar
            bg={isNavbarTransparent && screenWidth > 768 ? 'transparent' : ''}
            variant={isNavbarTransparent && screenWidth > 768 ? 'transparent' : ''}
            expand="md"
            className={isNavbarTransparent && screenWidth > 768 ? 'transparent-navbar' : 'custom-dark-navbar'}
            expanded={expanded}
        >
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img
                        src={getLogo()}
                        className="navbar-logo"
                        height={getLogoHeight()}
                        alt="Enerting logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    aria-label="Toggle navigation"
                    aria-expanded={expanded}
                    onClick={handleToggle}
                >
                    <span className="navbar-toggler-icon custom-toggler-icon"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link
                            as={NavLink}
                            to="/"
                            className="nav-link-home"
                            onClick={() => setExpanded(false)}
                        >
                            Inicio
                        </Nav.Link>
                        <Nav.Link
                            as={NavLink}
                            to="/nosotros"
                            className="nav-link-nosotros"
                            onClick={() => setExpanded(false)}
                        >
                            Nosotros
                        </Nav.Link>
                        {/* <Nav.Link
                                as={NavLink}
                                to="/contacto"
                                className="nav-link-nosotros"
                                onClick={() => setExpanded(false)}
                            >
                                Contacto
                            </Nav.Link> */}
                        <Nav.Link
                            as={NavLink}
                            to="/proyectos"
                            className="nav-link-proyectos"
                            onClick={() => setExpanded(false)}
                        >
                            Proyectos
                        </Nav.Link>
                        <Nav.Link
                            onClick={() => {
                                showContactModal();
                                setExpanded(false);
                            }}
                            className="nav-link-contact"
                        >
                            Contacto
                        </Nav.Link>
                        {/* <div className="nav-buttons">
                            <Button
                                className={buttonClasses.quote}
                                onClick={() => {
                                    showContactModal();
                                    setExpanded(false);
                                }}
                            >
                                <span>{getButtonText('contact')}</span>
                            </Button>
                            <Button
                                className={buttonClasses.quote}
                                onClick={() => {
                                    showQuoteModal();
                                    setExpanded(false);
                                }}
                            >
                                <span>{getButtonText('quote')}</span>
                            </Button>
                        </div> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

NavBar.propTypes = {
    isNavbarTransparent: PropTypes.bool.isRequired,
};

export default NavBar;