import FAQItem from './FAQItem';
import { faqData } from './constants';
import './FAQ.css';

const FAQ = ({ onContactClick }) => {
    return (
        <div className="faq">
            <h2 className='section-heading' style={{ textAlign: 'center' }}>Preguntas frecuentes</h2>
            <p className="faq-description">
                Descubre las preguntas más frecuentes sobre nuestra empresa y su respuesta.
            </p>
            {faqData.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
            ))}
            <p className="more-questions">
                ¿Más preguntas?<button onClick={onContactClick}>Contáctanos</button>
            </p>
        </div>
    );
};

export default FAQ;