import { useEffect, useState, useCallback } from 'react';
import NavBar from '../../components/navbar/NavBar';
import './Home.css';
import Hero from '../../components/hero/Hero';
import solarPanelImg from '../../assets/solar-panel-img.jpeg';
import SquareCard from '../../components/square-card/SquareCard';
import FAQ from '../../components/FAQ/FAQ';
import Squarecard1 from "../../assets/Squarecard-1.svg";
import Squarecard2 from "../../assets/Squarecard-2.svg";
import Squarecard3 from "../../assets/Squarecard-3.svg";
import CallToAction from '../../components/cta/CallToAction';
import FlowerCTA from "../../assets/flower-cta.svg";
import ProjectCarousel from '../../components/project-carousel/ProjectCarousel';
import { clientLogos } from '../../constants';
import ContactModal from '../../components/cta-modal/ContactModal';
import TestimonialsCarousel from '../../components/testimonials-carousel/TestimonialsCarousel';

const Home = () => {
    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = useCallback(() => {
        setShowModal(true);
    }, []);

    const handleModalClose = useCallback(() => {
        setShowModal(false);
    }, []);

    const [isNavbarTransparent, setIsNavbarTransparent] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setIsNavbarTransparent(position < 100);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showModal]);

    return (
        <div>
            <NavBar
                isNavbarTransparent={isNavbarTransparent}
                onShowModal={handleModalOpen}
            />
            <div className="main-content">
                <Hero />
                <div className="home-logos-section">
                    <div className="container text-center">
                        <h4>Estas empresas han confiado en nosotros</h4>
                        <div className="home-logos-container">
                            {clientLogos.map((logo, index) => (
                                <div key={index} className="home-logo">
                                    <img src={logo.src} alt={logo.alt} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="info-section d-flex align-items-center justify-content-center" style={{ marginTop: '2rem' }}>
                    <div className="container d-flex flex-wrap">
                        <div className="text-content col-md-6 special-section">
                            <h2 className="section-heading text-left">
                                <span className="line1">Especialistas en </span>
                                <span className="line2">energía solar</span>
                            </h2>
                            <p className="info-text text-left">
                                Conscientes del problema que representan los gases de efecto invernadero para nuestro planeta, así como del inevitable agotamiento de los combustibles fósiles, nos hemos propuesto como meta desarrollar proyectos que utilicen energías limpias tanto a nivel residencial como industrial.
                            </p>
                        </div>
                        <div className="image-content col-md-6 text-center mobile-image-nodisplay">
                            <img src={solarPanelImg} alt="Solar Panel" className="img-fluid" style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1' }} />
                        </div>
                    </div>
                </div>
                <div className="py-5" style={{ marginTop: '0rem' }}>
                    <div className="container text-center">
                        <h2 className="section-heading text-left">¿Qué hacemos?</h2>
                        <div className="card-section">
                            <SquareCard
                                icon={<img src={Squarecard1} alt="Diseño" />}
                                title="Diseño, Instalación y Venta"
                                content="De sistemas y equipos fotovoltaicos."
                                colors={{
                                    background: '#2b2b2b',
                                    text: '#F8F9FA',
                                    border: '#ccc',
                                }}
                                align="center"
                            />
                            <SquareCard
                                icon={<img src={Squarecard2} alt="Construcción" />}
                                title="Mantenimiento"
                                content="Preventivo y predictivo."
                                colors={{
                                    background: '#2b2b2b',
                                    text: '#F8F9FA',
                                    border: '#ccc',
                                }}
                                align="center"
                            />
                            <SquareCard
                                icon={<img src={Squarecard3} alt="Administración" />}
                                title="Soporte y Monitoreo"
                                content="24 horas, 7 días a la semana."
                                colors={{
                                    background: '#2b2b2b',
                                    text: '#F8F9FA',
                                    border: '#ccc',
                                }}
                                align="center"
                            />
                        </div>
                    </div>
                    <div className="projects-section" style={{ marginTop: '2rem', marginBottom: '0rem' }}>
                        <ProjectCarousel />
                    </div>
                    <div className="testimonials-section" style={{ marginTop: '-2rem', paddingTop: '1rem' }}>
                        <div className="testimonials-section">
                            <TestimonialsCarousel />
                        </div>
                    </div>
                    <div className="info-section d-flex align-items-center justify-content-center py-5" style={{ marginTop: '0rem' }}>
                        <div className="container d-flex flex-wrap">
                            <FAQ onContactClick={handleModalOpen} />
                        </div>
                    </div>
                    <div>
                        <div className="container d-flex flex-wrap">
                            <CallToAction
                                svgSource={FlowerCTA}
                                title="Obtén una asesoría."
                                text="Confía en nosotros para la transición de tu hogar o empresa hacia la energía solar."
                                buttonText="Solicitar"
                                onButtonClick={handleModalOpen}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ContactModal show={showModal} onClose={handleModalClose} />
        </div>
    );
};

export default Home;