import { useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import NavBar from '../../components/navbar/NavBar';
import Image1 from "../../assets/Image-1-about.webp";
import Image2 from "../../assets/Image-2-about.webp";
import CallToAction from '../../components/cta/CallToAction';
import SquareCard from '../../components/square-card/SquareCard';
import Squarecard4 from "../../assets/Squarecard-4.svg";
import Squarecard5 from "../../assets/Squarecard-5.svg";
import SmallHand from "../../assets/small-hand.svg";
import SmallHandt from "../../assets/small-handt.svg";
import SmallHeart from "../../assets/small-heart.svg";
import SmallTriangle from "../../assets/small-triangle.svg";
import SmallCard from '../../components/small-card/SmallCard';
import './Nosotros.css';
import ContactModal from '../../components/cta-modal/ContactModal';
import FlowerCTA from "../../assets/flower-cta.svg";
import { clientLogos } from '../../constants';
import Certificate from '../../components/certificates/Certificates';
import Certificate1 from "../../assets/certificate-1.webp"
import Certificate2 from "../../assets/certificate-2.webp"
import Certificate3 from "../../assets/certificate-3.webp"
import Certificate4 from "../../assets/certificate-4.webp"
import Certificate5 from "../../assets/certificate-5.webp"

const Nosotros = () => {
    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    return (
        <div>
            <NavBar isNavbarTransparent={false} />
            <div className="main-content nav-padding">
                <div className="container">
                    <h1 className='page-title'>Sobre Nosotros</h1>
                    <h3 className='page-subtitle'>{null}</h3>

                    <div className="text-section align-items-center py-5">
                        <Row className="align-items-center">
                            <Col md={6}>
                                <h5>Enerting</h5>
                                <p>
                                    Somos una empresa comprometida con la eficiencia energética, aplicando las más modernas tecnologías en nuestros productos y servicios. Conscientes del problema que representa para nuestro planeta los gases de efecto invernadero, así como el inevitable agotamiento de los combustibles fósiles, nos hemos trazado como meta desarrollar proyectos con energías limpias a nivel residencial e industrial.
                                </p>
                            </Col>
                            <Col md={6} className="image-section text-center">
                                <Image src={Image1} alt="Image-1" fluid style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1' }} />
                            </Col>
                        </Row>
                    </div>

                    <div className="text-section align-items-center py-5">
                        <Row className="align-items-center">
                            <Col md={6} className="image-section text-center">
                                <Image src={Image2} alt="Image-2" fluid style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1' }} />
                            </Col>
                            <Col md={6} className="text-content">
                                <h5>Sobre Nosotros</h5>
                                <p>
                                    Nuestra empresa está conformada por especialistas en ingeniería energética y finanzas, quienes aprovechando que el marco regulatorio local es propicio para el desarrollo de proyectos de energías limpias, se han unido para hacer posible que su casa o negocio pueda verse beneficiado económicamente con este tipo de proyectos. Hoy en día usted puede disponer en su casa o su negocio sistemas de energías renovables, obteniendo así un considerable ahorro de energía además de otros incentivos económicos.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className="py-5" style={{ backgroundColor: '#39A52E' }}>
                <div className="container">
                    <h2 className='section-heading mb-4 text-left' style={{ color: 'white' }}>
                        Nuestra Filosofía
                    </h2>
                    <div className="card-section card-section-about">
                        <SquareCard
                            icon={<img src={Squarecard4} alt="Square Card 4" />}
                            title="Misión"
                            content="Asesorar en la toma de decisiones para el desarrollo de proyectos de energía fotovoltaica, aportando experiencia y mano de obra calificada, generando energía limpia y amigable con el medio ambiente."
                            colors={{
                                background: '#FFFFFF',
                                text: '#303231',
                                border: '#ccc',
                            }}
                            align="left"
                        />
                        <SquareCard
                            icon={<img src={Squarecard5} alt="Square Card 5" />}
                            title="Visión"
                            content="Nuestra visión es ser la empresa de mejor servicio en el área de energía renovable, ofreciendo a nuestros clientes soluciones confiables, aplicando las mejores técnicas y tecnología."
                            colors={{
                                background: '#FFFFFF',
                                text: '#303231',
                                border: '#ccc',
                            }}
                            align="left"
                        />
                    </div>
                </div>
            </div>
                            <div className="values-section">
                    <div className="container">
                        <h2 className="values-heading">
                            Valores de nuestra empresa
                        </h2>
                        <div className="small-cards-grid">
                            <div className="small-card">
                                <div className="small-card-icon">
                                    <img src={SmallHeart} alt="Confiabilidad" />
                                </div>
                                <div className="small-card-content">
                                    <h3>Confiabilidad</h3>
                                    <p>La ética profesional y la transparencia es parte de nuestra cultura empresarial.</p>
                                </div>
                            </div>
                            <div className="small-card">
                                <div className="small-card-icon">
                                    <img src={SmallHand} alt="Profesionalidad" />
                                </div>
                                <div className="small-card-content">
                                    <h3>Profesionalidad</h3>
                                    <p>Apegados a las normas técnicas y a las buenas prácticas para generar soluciones seguras.</p>
                                </div>
                            </div>
                            <div className="small-card">
                                <div className="small-card-icon">
                                    <img src={SmallTriangle} alt="Puntualidad" />
                                </div>
                                <div className="small-card-content">
                                    <h3>Puntualidad</h3>
                                    <p>Nos apasiona satisfacer las necesidades de nuestros clientes con responsabilidad.</p>
                                </div>
                            </div>
                            <div className="small-card">
                                <div className="small-card-icon">
                                    <img src={SmallHandt} alt="Eficiencia" />
                                </div>
                                <div className="small-card-content">
                                    <h3>Eficiencia</h3>
                                    <p>Nos mantenemos en mejora continua para lograr mejores resultados.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="py-5" style={{ backgroundColor: '#FFFFFF' }}>
                <div className="container">
                    <div className="row align-items-center" >
                        <div className="col-md-4">
                            <h2 className="section-heading mb-3" style={{ textAlign: 'left' }}>
                                Nuestros Clientes
                            </h2>
                            <p className="info-text mb-4" style={{ textAlign: 'left' }}>
                                Nos enorgullece colaborar con una amplia gama de clientes, desde clientes residenciales hasta clientes corporativos, agropecuarios e industriales de gran trayectoria.
                            </p>
                        </div>
                        <div className="col-md-7">
                            <div className="client-logos-container">
                                {clientLogos.map((logo, index) => (
                                    <div key={index} className={`client-logo ${logo.className || ''}`}>
                                        <img src={logo.src} alt={logo.alt} className="img-fluid" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-5" style={{ backgroundColor: '#303231' }}>
            <div className="container">
        <h2 className='section-heading mb-4 text-left' style={{ color: 'white' }}>
            Cualificaciones
        </h2>
        <div className="container-certificate">
            {[
                { img: Certificate1, text: "INSTALADOR CERTIFICADO" },
                { img: Certificate2, text: "INSTALADOR CERTIFICADO" },
                { img: Certificate3, text: "EMPRESA VINCULADA" },
                { img: Certificate4, text: "REGLAMENTO 522-06" },
                { img: Certificate5, text: "MIEMBRO ASOCIADO" }
            ].map((cert, index) => (
                <div key={index} className="certificate-item">
                    <Certificate 
                        imageSrc={cert.img}
                        text={cert.text}
                    />
                </div>
            ))}
        </div>
    </div>
</div>
            <div className="py-5">
                <div className="container">
                    <CallToAction
                        svgSource={FlowerCTA}
                        title="Obtén una asesoría."
                        text="Confía en nosotros para la transición de tu hogar o empresa hacia la energía solar."
                        buttonText="Solicitar"
                        onButtonClick={handleModalOpen}
                    />
                </div>
            </div>
            <ContactModal show={showModal} onClose={handleModalClose} />
        </div>
    );
};

export default Nosotros