import PropTypes from 'prop-types';
import './SmallCard.css';

const SmallCard = ({ icon, iconText, title, content, colors }) => {
    const { background, text, border } = colors;

    return (
        <div className="small-card" style={{ backgroundColor: background, color: text, borderColor: border }}>
            <div className="small-card-content">
                <div className="text-content-card">
                    <h3>{title}</h3>
                    <p>{content}</p>
                </div>
                <div className="small-card-icon-wrapper">
                    <div className="card-icon">
                        <div className="small-icon-container">
                            {icon}
                            {iconText && <span className="icon-text">{iconText}</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SmallCard.propTypes = {
    icon: PropTypes.node.isRequired,
    iconText: PropTypes.string,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    colors: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        border: PropTypes.string,
    }),
};

SmallCard.defaultProps = {
    iconText: null,
    colors: {
        background: '#fff',
        text: '#000',
        border: 'transparent',
    },
};

export default SmallCard;
