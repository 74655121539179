import { useState } from 'react';
import NavBar from '../../components/navbar/NavBar';
import Gallery from '../../components/gallery/Gallery';
import { GALLERY_PROJECTS } from './constants';
import './Galeria.css'

const Galeria = () => {
    const [projects, setProjects] = useState(GALLERY_PROJECTS);

    const handleSearch = (searchTerm) => {
        const filteredProjects = GALLERY_PROJECTS.filter(project =>
            project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            project.subtitle.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setProjects(filteredProjects);
    };

    const handleFilter = () => {
        // Implement filter logic here
        console.log('Filter button clicked');
        // For now, let's just reset to all projects
        setProjects(GALLERY_PROJECTS);
    };

    return (
        <div>
            <NavBar isNavbarTransparent={false} />
            <div className="main-content nav-padding">
                <h1 className='page-title'>Galería de Proyectos</h1>
                <h3 className='page-subtitle'>Explora nuestro portafolio<br></br>  de proyectos destacados</h3>
                <div className="container d-flex flex-wrap" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                    <Gallery
                        projects={projects}
                        onSearch={handleSearch}
                        onFilter={handleFilter}
                    />
                </div>
            </div>
        </div >
    );
};

export default Galeria;