import PropTypes from 'prop-types';
import './SquareCard.css';

const SquareCard = ({ icon, iconText, title, content, colors }) => {
    const { background, text, border } = colors;

    return (
        <div className="card" style={{ backgroundColor: background, color: text, borderColor: border }}>
            <div className="card-wrapper">
                <div className="square-card-icon">
                    <div className="square-icon-container">
                        {icon}
                        {iconText && <span className="icon-text">{iconText}</span>}
                    </div>
                </div>
                <div className="card-content">
                    <h3>{title}</h3>
                    <p>{content}</p>
                </div>
            </div>
        </div>
    );
};

SquareCard.propTypes = {
    icon: PropTypes.node.isRequired,
    iconText: PropTypes.string,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    colors: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        border: PropTypes.string,
    }),
};

SquareCard.defaultProps = {
    iconText: null,
    colors: {
        background: '#fff',
        text: '#000',
        border: 'transparent',
    },
};

export default SquareCard;